.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: #f8f8f8;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
    font-family: 'Lato';
  }
  
  .siteName {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .nav {
    display: flex;
    align-items: center;
  }
  
  .link {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .link:hover {
    color: #007bff;
  }