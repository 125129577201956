@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400&display=swap');

.artifact {
  /* display: inline-block; */
  vertical-align: top;
  white-space: normal;
  margin-right: 20px; /* Adjust spacing between artifacts */
}

.artifactContent {
  display: block;
}

.artifactHeader {
  white-space: nowrap;
}

.explanation {
  white-space: normal;
  max-width: 400px; /* Adjust this value as needed */
}

.artifact {
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 1px solid #ccc;
  }
  
  .artifactHeader {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .toggleIcon {
    margin-right: 5px;
    cursor: pointer;
  }
 
  .concrete {
    color: #6b9c45;
  }

  .general {
    color: #919ef0;
  }

  .abstract {
    color: #f6bc74;
  }
  
  .associationButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px;
    margin-left: 5px;
    opacity: 0.7;
    transition: opacity 0.2s ease, background-color 0.2s ease;
  }
  
  .associationButton:hover {
    opacity: 1;
  }
  
  .associationButton.active {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }
  
  .associationButton:disabled {
    cursor: default;
    opacity: 0.5;
  }
  
  .artifactContent {
    margin-top: 5px;
  }
  
  .explanation {
    margin: 5px 0;
    font-size: 14px;
  }

  .deleteButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8em;
    padding: 0 5px;
    margin-left: auto;
    opacity: 0.6;
    transition: opacity 0.2s;
  }
  
  .deleteButton:hover {
    opacity: 1;
  }