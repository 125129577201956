.artifact-content {
    transition: background-color 0.3s ease, padding 0.3s ease;
    border-radius: 4px;  /* Optional: adds rounded corners */
  }
  
  .artifact-highlight {
    background-color: rgba(255, 255, 0, 0.2);  /* Light yellow background */
  }
  
  .artifact-highlight-fade {
    background-color: transparent;
  }