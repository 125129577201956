.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    word-break: break-all;
    white-space: normal;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 600px;
    width: 90%;
    font-family: 'Lato';
  }
  
  .modalContent h2 {
    margin-top: 0;
  }

  .modalContent p, .modalContent li {
    word-wrap: break-word; /* Allow long words to break and wrap */
    overflow-wrap: break-word; /* Alternative property for better browser support */
    hyphens: auto; /* Enable automatic hyphenation */
  }
  
  .modalContent button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Lato';
  }
  
  .modalContent button:hover {
    background-color: #0056b3;
  }