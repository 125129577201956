.explorerContainer {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.artifactContainer {
  display: inline-block;
  white-space: nowrap;
}

.explorerContainer {
    display: flex;
    flex-direction: column;
    height: 90vh;
    padding: 20px;
    padding-top: 5px;
    box-sizing: border-box;
  }
  
  .artifactContainer {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 60px; /* Make room for the button container */
    font-family: 'Lato';
  }
  
  .buttonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-top: 1px solid #ccc;
  }
  
  .iconButton {
    font-size: 24px;
    margin: 0 10px;
    padding: 10px;
    border: none;
    background: none;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
  }
  
  .iconButton:hover {
    transform: scale(1.1);
  }
  
  .iconButton:active {
    transform: scale(0.9);
  }

  .customInputForm {
    display: flex;
    /* margin-top: 10px; */
  }
  
  .customInput {
    flex-grow: 0;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
  }
  
  .customInputButton {
    background: none;
    border: 1px solid #ccc;
    border-left: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 0 4px 4px 0;
  }
  
  .customInputButton:hover {
    background-color: #f0f0f0;
  }
  
  .customInputButton:disabled {
    cursor: default;
    opacity: 0.5;
  }

  .explorerContainer {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.splitScreen {
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100% - 60px); /* Adjust based on your header and button container height */
}

.textGraph {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #ccc;
}

.d3Graph {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.artifactContainer {
  padding: 20px;
}

.buttonContainer {
  height: 60px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
}

.customInputForm {
  padding: 10px;
  /* border-top: 1px solid #ccc; */
}

/* Adjust other styles as needed */

.explorerContainer {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.splitScreen {
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100% - 60px); /* Adjust based on your button container height */
}

.textGraph {
  width: 50%;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #ccc;
}

.artifactScrollContainer {
  min-height: 100%;
  padding-bottom: 80px; /* Add padding to prevent content from being covered by the bottom bar */
}

.artifactContainer {
  padding: 20px;
}

.d3Graph {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.buttonContainer {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-top: 1px solid #ccc;
  background-color: white;
  position: fixed; /* Fix the button container to the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it's above other content */
}

/* ... rest of the styles remain the same ... */

.iconButton {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.customInputForm {
  display: flex;
  flex: 1;
  margin-left: 20px;
}

.customInput {
  flex: 1;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  font-family: 'Lato';
}

.customInputButton {
  padding: 5px 10px;
  font-size: 16px;
  background-color: #0095e545;
  color: white;
  /* border: 1px solid #8ACE00; */
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.customInputButton:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
  cursor: not-allowed;
}

/* ... other existing styles ... */